import _ from 'lodash'
import axios from '../../common/axios.js'
import moment from 'moment'
import Input from '../../common/forms/Input.vue'

export default {
	data() {
		return {
			image: null,
			title: '',
			shooting_date: moment().format('D/MM/YYYY'),
			errors: {},
			imagePreview: null,
			showPreview: false,
			image_erreur: null,
			image_succes: null,
			reader: new FileReader(),
			ajout_photo: false,
			more_photos: false,
			limit_photos: 3,
			images: [],
			zoom: null
		}
	},
	props: ['element'],
	created(){
		let self = this
		let _images = []
		if(this.element.value && this.element.value.length){
			this.element.value.forEach(function(image){
				_images.push({name: image.file, title: image.title, shooting_date: image.shooting_date, binary: false})
				self.loadImage(image.file)
			})
			self.images = _images
		}
		this.limit_photos = this.$store.state.parcelle.affichage == 'synthetique' ? 3 : 5
	},
	methods: {
		loadImage(image_name){
			let self = this
			axios.get('api=inex/views/{view_code}/parcelles/{parcelle_id}/images/'+image_name, {responseType: 'blob'})
				.then(function (response) {
					var reader = new window.FileReader();
					reader.readAsDataURL(response.data); 
			        reader.onload = function() {
						let _images = _.cloneDeep(self.images)
						_images.forEach(function(_image){
							if(_image.name == image_name){
								_image.binary = reader.result
							}
						})
						self.images = _images;
			        }

    			}).catch(function (error) {

    			})
		},

		submitForm(){
			let self = this

			if(!this.validForm()) return

			let data = new FormData();
			data.append('image', this.image, this.image.name);
			data.append('title', this.title);
			data.append('shooting_date', this.shooting_date);

			let urlGet = []
			if(this.title) urlGet.push('title='+this.title)
			if(this.shooting_date) urlGet.push('shooting_date='+this.getShootingDateUrl())
			urlGet = urlGet.length ? '?'+urlGet.join('&') : ''

			axios.post('api=inex/views/{view_code}/parcelles/{parcelle_id}/images'+urlGet, data, {
			  headers: {
			    'accept': 'application/json',
			    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
			  }
			})
			  .then((response) => {

			  	if(response.data.code == 200){

				  	//AS--> Ajoute l'image au référentiel
				    let images = _.cloneDeep(self.images)
				    images.push({
				    	name: response.data.data,
						title: self.title,
						shooting_date: self.getShootingDateUrl(),
				    	binary: false
				    })
				    self.images = images;
				    self.loadImage(response.data.data)

				    //AS--> Reset le formulaire
				    self.resetForm()
				    self.resetImage()

				    //AS--> Affiche le succès
				    /*self.image_succes = true;
				    setTimeout(function(){
				    	self.image_succes = false
				    },2000)*/

				    //AS--> Masque le formulaire
				    self.ajout_photo = false

			  	}else{

				  	//AS--> Reset le formulaire
				    self.resetForm()
				    self.resetImage()

				    //AS--> Affiche l'erreur
				    self.image_erreur = response.data.message
			  	}

			  }).catch((error) => {


			  });

		},
		onFileChange(event){
			
			this.image = event.target.files[0];

			this.showPreview = false;
			this.imagePreview = null;
			this.image_erreur = null;

			/*
			Add an event listener to the reader that when the file
			has been loaded, we flag the show preview as true and set the
			image to be what was read from the reader.
			*/
			this.reader.addEventListener("load", function () {
				this.showPreview = true;
				this.imagePreview = this.reader.result;
				this.valeur = this.image;
			}.bind(this), false);

			
			if(this.checkImage(this.image)){
				this.reader.readAsDataURL( this.image );
			}
		},
		checkImage(image){
			return image && /\.(jpe?g|png|gif)$/i.test( image.name );
		},
		resetImage(){
			this.image = null;
			this.imagePreview = null;
			this.showPreview = null;
			this.image_erreur = null;
		},
		deleteImage(image){
			let self = this
			this.$dialog.confirm(self.$t('parcelle_delete_confirm'), { loader: true })
				.then(dialog => {
					dialog.close();
					axios.delete('api=inex/views/{view_code}/parcelles/{parcelle_id}/images/'+image.name)
						.then(function (response) {
		   					self.images = self.images.filter(a => a.name !== image.name)

						}).catch(function (error) {

						})

				});
		},
		ajouterPhoto(){
			let self = this
			this.ajout_photo = !this.ajout_photo
			setTimeout(function(){
				if(self.ajout_photo){
					self.$refs.input_photo.click()
				}
			}, 10)
		},
		editionOff(){
			this.ajout_photo = false
			this.resetForm()
			this.resetImage()
		},
		validForm(){
			let regexDate = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/(19|20)\d\d$/
			let errors = {};
			if(!this.image) errors['image'] = ['Indiquez une photo']
			if(this.shooting_date && !this.shooting_date.match(regexDate)) errors['shooting_date'] = ['Indiquez une date au format jj/mm/aaaa']
			this.errors = errors;
			return _.size(errors) == 0
		},
		resetForm(){
			this.image         = null;
			this.title         = '';
			this.shooting_date = moment().format('D/MM/YYYY');
		},
		getShootingDateUrl(){
			let date = this.shooting_date.split('/')
			// Pour chaque élément, on vérifie qu'il fait 2 chiffres, sinon on le complète avec un 0
			date = date.map(function(element){	
				return element.length == 1 ? '0'+element : element
			})
			// Si on a 3 éléments, on les inverse et on les joins avec des -
			return date.length == 3 ? date.reverse().join('-') : ''
		},
		getDateAffichage(date){
			return date ? date.split('-').reverse().join('/') : ''
		}
	},
	computed:{
		isErreur(){
			return this.image && !this.checkImage(this.image);
		},
		getSrc(){
			if(this.imagePreview){
				return this.imagePreview;
			}else{
				return null;
			}
		},
		first_photos(){
			let limit = this.$store.state.ui.class_impression == 'print-parcelle' ? 16 : this.limit_photos
			if(this.images.length == limit+1) return this.images
			return this.images.slice(0, limit);
		},
		next_photos(){
			let limit = this.$store.state.ui.class_impression == 'print-parcelle' ? 16 : this.limit_photos
			if(this.images.length == limit+1) return []
			return this.images.slice(limit);
		}
	},
	components:{
		Input
	}
}