import axios from 'axios'
import Chip from '../../common/ui/chip.vue'

export default {
    name: 'ParcellesListes',
	props: ['element'],
    methods: {
        removeList(list){
            let self = this
            this.$dialog.confirm(this.$t('parcelle_listes_retirer_confirmation'), {loader: true})
			.then(dialog => {
				setTimeout(function(){
					dialog.close();
				}, 200);
				self.$store.dispatch('removeParcelFromList', list)
			})
        }
    },
    components: {
        Chip
    }
}